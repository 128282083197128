<template>
    <div v-if="Object.keys(labels).length && data.company" class="space-home client-content-padding">
        <div class="description" v-html="data.company.description"></div>
        <div class="accordion" id="spaceFaq">
            <div v-for="(faq, index) in data.company.faqs" class="card" :key="`faq-${index}`">
                <div :class="{['card-header']: true, opened: faqStatuses[index] ? true : false}" :id="`faqHeading${index}`">
                    <h2 class="mb-0">
                        <button
                            @click.self="toggleFaq(index)"
                            class="btn btn-link btn-block text-left no-focus-outline"
                            type="button"
                            data-toggle="collapse"
                            :data-target="`#faqCollapse${index}`" :aria-expanded="index == 0 ? true : false"
                            :aria-controls="`faqCollapse${index}`"
                        >
                            {{faq.title}}
                            <div class="chevron"></div>
                        </button>
                    </h2>
                </div>
                <div :id="`faqCollapse${index}`" :class="{collapse: true, show: index == 0 ? true : false}" :aria-labelledby="`faqHeading${index}`" data-parent="#spaceFaq">
                    <div v-html="faq.answer" class="card-body"></div>
                </div>
            </div>
        </div>

        <div class="featured" v-if="data && data.company && data.company.featuredCourses && data.company.featuredCourses.length">
            <h5>
                {{ labels.featured_courses }}
            </h5>
            <div class="space-slider">
                <VueSlickCarousel v-bind="slickSettings">
                    <div v-for="course in data.company.featuredCourses" :key="course.id" class="space-slide">
                        <!-- <router-link :to="{name: 'space-course', params: {spaceId: $route.params.spaceId}, courseId: course._id}">
                            <img loading="lazy" :src="course.image" />
                            <p>
                                {{ course.title }}  
                            </p>
                        </router-link> -->
                        <a :href="'#'">
                            <img loading="lazy" :src="course.image" />
                            <p>
                                {{ course.title }}  
                            </p>
                        </a>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
    name: 'SpaceHome',
    components: {
        VueSlickCarousel: () => import('vue-slick-carousel')
    },
    props: {
        labels: {
            type: Object,
            default: () => {
                return {}
            }
        },
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    mounted() {
        this.resetSelectedFaqs(true);
    },
    data() {
        return {
            slickSettings: {
                arrows: false,
                dots: false,
                slidesToShow: 4,
                slidesToScroll:1,
                swipe:true,
                autoplay:false,
                autoplaySpeed: 4000,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            swipe:true,
                            arrows: false,
                            dots: false
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            swipe:true,
                            arrows: false,
                            dots: false
                        },
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            swipe:true,
                            arrows: false,
                            dots: false
                        },
                    },
                ],
            },
            faqStatuses: {}
        }
    },
    methods: {
        resetSelectedFaqs(firstActive) {
            for (let index = 0; index < this.data.company.faqs.length; index++) {
                this.$set(this.faqStatuses, index, firstActive && index == 0 ? true : false);
            }
        },
        toggleFaq(index) {
            if (!this.faqStatuses[index]) {
                this.resetSelectedFaqs();
                this.faqStatuses[index] = true;
            } else {
                this.faqStatuses[index] = false;
            }
        }
    }
}
</script>

<style scoped lang="scss">
    @import '../styles/spaces_common.scss';

    .space-home {
        .accordion {
            margin-top: 2rem;

            button {
                &:hover {
                    text-decoration: none;
                }
            }

            .card-header {
                button {
                    display: flex;
                    align-items: center;

                    .chevron {
                        margin-left: auto;
                        border-right: 4px solid; 
                        border-bottom: 4px solid;
                        height: 10px;
                        width: 10px;
                        transition: transform 300ms linear;
                        transform: rotate(-314deg);
                    }
                }
            }

            .card-header.opened {
                color: $color_over_focus;
                background-color: $focus_color;

                button {
                    color: $color_over_focus;

                    .chevron {
                        transform: rotate(227deg);
                    }
                }
            }
        }

        .featured {
            margin-top: 2rem;
            @include client-margin;

            .space-slider {
                margin: 0 -15px;
            }

            .space-slide {
                padding: 0 15px;
                border-radius: 10px;

                a {
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    border-radius: 10px;

                    p {
                        margin-bottom: 0px;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $focus_color;
                        color: $color_over_focus;
                        opacity: 0;
                        pointer-events: none;
                        z-index: 2;
                        transition: opacity 300ms ease-in;
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                    }

                    &:hover {
                        p {
                            opacity: 1;
                        }
                    }

                    img {
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        height: 220px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
</style>